@import 'styles/variables';
@import 'styles/mixins';

@font-face {
  font-family: Euclid Flex;
  src: url(assets/fonts/EuclidFlexRegular.ttf);
}

@font-face {
  font-family: Euclid Flex;
  src: url(assets/fonts/EuclidFlexMedium.ttf);
  font-weight: 500;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root {
    height: 100%;

    --toastify-color-success: #7ee081;
    --toastify-font-family: $jost;
    --toastify-icon-color-success: #7ee081;
    --toastify-color-progress-success: #7ee081;
  }
}

a {
  text-decoration: none;
  color: $primary-main;
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.yarl__no_scroll {
  padding-right: 0 !important;
}
