@import '../../styles/variables';
@import '../../styles/mixins';

.navigationContainer {
  display: flex;
  align-items: center;
  color: $primary-main;
  padding: 1.25rem 1.125rem;
  right: 0;
  z-index: 8;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(0.1875rem);
  justify-content: space-between;
  box-shadow: rgba(34, 51, 84, 0.2) 0 0.125rem 0.5rem -0.1875rem,
    rgba(34, 51, 84, 0.1) 0rem 0.3125rem 1.375rem -0.25rem;
  width: 100%;

  @include bp(m) {
    height: 5rem;
    left: 18.125rem;
    padding: 0 1.125rem;
    position: fixed;
    width: calc(100% - 18.125rem);
  }

  .iconItems {
    display: flex;
    align-items: center;

    button {
      color: $primary-main;
    }

    .priceNotificationIcon {
      position: relative;
    }

    .priceNotificationsBadge {
      position: absolute;
      background-color: $error-color;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-white;
      min-width: 1.25rem;
      padding: 0.0625rem;
      border-radius: 3.125rem;
      top: 0.125rem;
      right: 0.125rem;
      font-size: 0.8rem;
    }
  }
}

.notificationPopoverContent {
  background-color: $color-white;
  border-radius: 0;
  background-image: none;
  overflow: hidden auto;
  min-width: 12.5rem;
  max-height: 31.5rem;
  outline: 0;
  box-shadow: $notification-popover-shadow 0 0 0.125rem 0,
    $notification-popover-shadow 0 0.75rem 1.5rem 0;
  max-width: 100%;
  opacity: 1;
  transform: none;
  transition: opacity 230ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 153ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  .notificationPopoverList {
    list-style: none;
    margin: 0;
    padding: 0;
    outline: 0;

    .notificationPopoverListItem {
      list-style: none;
      background-color: $notification-popover-not-seen-background;
      border-bottom: 0.0625rem solid $color-grey-light;
      border-left: 0.0625rem solid $primary-main;
      cursor: pointer;
      user-select: none;
      line-height: 1.7;
      font-family: $jost;
      display: flex;
      flex-direction: column;
      text-decoration: none;
      padding: 0.625rem 1.25rem;
      box-sizing: border-box;
      white-space: nowrap;
      min-width: 12.5rem;
      white-space: pre-wrap;
      transition: 0.2s;

      &.isSeen {
        background-color: transparent;
        border-left: 0.0625rem solid transparent;
      }

      &:hover {
        background-color: $notification-popover-hover-background;
        border-left: 0.0625rem solid transparent;
      }

      .notificationPopoverItemLocationName {
        font-size: 1.2rem;
        font-weight: 600;
      }

      .notificationPopoverItemRemoteLocationName {
        font-size: 1.05rem;
        color: $primary-main;
      }

      .notificationPopoverItemPrice {
        font-size: 1rem;
      }

      .notificationPopoverItemIntegrationName {
        font-size: 0.8rem;
        color: $color-grey;
      }
    }
  }
}
