@import '../../styles/variables';
@import '../../styles/mixins';

.illustrationContainer {
  display: flex;
  justify-content: center;
  width: 100%;

  img {
    width: 100%;
    max-width: 70rem;
    height: auto;
    padding: 1.44rem 1rem 1.62rem;

    @include bp(sm) {
      padding: 3.44rem 2.875rem 2.5rem;
    }

    @include bp(l) {
      padding: 3.44rem 10rem 2.5rem;
    }
  }
}

.newsletterContainer {
  background-color: $sand-color;
  padding: 3.75rem 1rem;
  position: relative;
  z-index: 15;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @include bp(sm) {
    margin: 0 2.875rem -4rem;
    padding: 4rem 1rem;
  }

  @include bp(l) {
    margin: 0 10rem -4rem;
  }

  .newsletterContent {
    width: 100%;
    max-width: 38.1875rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .newsletterTitle {
      color: $color-white;
      font-family: $euclidFlex;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-bottom: 2rem;
      text-align: center;

      @include bp(sm) {
        font-size: 2rem;
      }
    }

    .newsletterFormAndButtonContainer {
      display: flex;
      height: 5.125rem;
      max-width: 25.5rem;

      .form {
        width: 100%;

        @include bp(s) {
          width: 16.25rem;
        }

        :global(.MuiOutlinedInput-notchedOutline) {
          border: none;
          background-color: $color-white;
          z-index: -1;
          border-radius: 0.375rem 0rem 0rem 0.375rem;
        }

        input {
          &::placeholder {
            color: $sand-color;
            font-family: $euclidFlex;
            opacity: 1;
            font-weight: 500;
            font-size: 1rem;
          }
        }
      }

      button {
        border-radius: 0 0.375rem 0.375rem 0;
        height: 3.375rem;
        width: 9.5rem;
        text-transform: uppercase;
        padding: 0;
        font-family: $euclidFlex;
        font-size: 1rem;
        font-weight: 500;

        &:hover {
          background-color: $gold-color;
        }
      }
    }

    .newsletterInfo {
      display: flex;
      padding: 2rem 0 0;
      justify-content: center;

      @include bp(sm) {
        padding: 2rem 1.2rem 0;
        justify-content: space-between;
      }

      .columnInfo {
        display: flex;
        width: 50%;

        &.workingHoursColumn {
          min-width: 13rem;
          padding-right: 1.5rem;
        }

        .columnIcon {
          .icon {
            padding-right: 1rem;
            padding-top: 0.3rem;

            @include bp(sm) {
              transform: scale(1.4);
              padding-top: 0.7rem;
              padding-right: 1.3rem;
            }
          }
        }

        .columnItems {
          .columnTitle {
            color: $color-white;
            font-family: $euclidFlex;
            font-size: 0.8125rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding-bottom: 0.62rem;

            @include bp(sm) {
              font-size: 1rem;
            }
          }

          .columnItem {
            color: $color-white;
            font-family: $roboto;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @include bp(sm) {
              font-size: 0.875rem;
            }
          }
        }
      }
    }
  }
}

.footerContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $primary-main;

  @include bp(sm) {
    height: 24.375rem;
  }

  .footerContent {
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;
    padding: 4.12rem 1.19rem;

    @include bp(sm) {
      flex-direction: row;
      justify-content: space-between;
      padding: 8.12rem 2.875rem 5.25rem;
    }

    @include bp(l) {
      padding: 8.12rem 10rem 5.25rem;
    }

    .leftContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.8rem;
      padding-bottom: 3.12rem;

      @include bp(sm) {
        max-width: 20rem;
        align-items: start;
        padding-bottom: 0;
      }

      img {
        height: 4.625rem;
      }

      .description {
        font-family: $roboto;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 26px;
        color: $color-white;
        text-align: center;

        @include bp(sm) {
          text-align: left;
          font-size: 1rem;
        }
      }
    }

    .rightContent {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include bp(sm) {
        flex-direction: row;
        width: 60%;
        justify-content: flex-end;
        margin-top: 1rem;
      }

      .columnInfo {
        display: flex;
        flex-direction: column;
        text-align: center;

        @include bp(sm) {
          text-align: left;
          padding-bottom: 0;
        }

        &.contactColumn {
          @include bp(sm) {
            text-align: right;
          }
        }

        &.navigationColumn {
          width: 30%;
          padding-bottom: 3.12rem;

          @include bp(sm) {
            margin-right: 2rem;
            padding-bottom: 0;
          }
        }

        .columnItems {
          display: flex;
          flex-direction: column;
          gap: 0.9rem;

          .columnItem {
            font-family: $roboto;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.5rem;
            color: $color-white;

            &.navigationItem {
              font-family: $euclidFlex;
              text-transform: uppercase;
              cursor: pointer;
            }

            &.phoneNumber {
              font-size: 1.5rem;

              @include bp(l) {
                font-size: 2rem;
              }
            }

            &.email {
              font-size: 1rem;
              font-weight: 400;
              padding-top: 0.3rem;
              padding-bottom: 0.8rem;

              @include bp(sm) {
                font-size: 1.5rem;
              }
            }
          }

          .socials {
            gap: 0.7rem;
            display: flex;
            justify-content: center;

            @include bp(sm) {
              justify-content: end;
              gap: 2.2rem;
              padding-right: 0.7rem;
            }

            .socialIcon {
              cursor: pointer;

              @include bp(sm) {
                transform: scale(1.6);
              }
            }
          }
        }
      }
    }
  }
}
