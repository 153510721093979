@import '../../styles/variables';
@import '../../styles/mixins';

.headerContainer {
  color: $primary-main;
  border-radius: 0.3125rem;
  font-family: $jost;
  font-weight: 600;
  font-size: 1.125rem;
}

.fileFieldContainer {
  display: flex;
  flex-direction: column;

  .fileField {
    background-color: $bright-color;
    transition: padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 0.5rem;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    text-align: left;
    overflow: hidden;
    display: flex;
    outline: none;

    @include bp(s) {
      flex-direction: row;
    }

    input {
      display: none;
    }

    .fileContainer {
      width: 100%;
      height: 8.125rem;

      @include bp(s) {
        width: 10rem;
      }

      .fileSelectedContainer {
        border: 0.0625rem dashed $color-grey-light;
        border-radius: 0.75rem;
        padding: 0.75rem;
        height: 100%;

        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }

        .imageContainer {
          width: 8.75rem;
          height: 6.875rem;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 0.5rem;
          }
        }

        .innerFileSelectContainer {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 0.625rem;
          height: 100%;
          background: rgba(218, 222, 227, 0.5);
          color: $color-grey;

          .innerFileIcon {
            color: $color-grey;

            &.errorFile {
              color: $red-color;
              opacity: 0.8;
            }

            &.successFile {
              color: $primary-main;
              opacity: 0.8;
            }
          }

          .innerFileSelectLabel {
            margin-top: 0.75rem;
            font-family: $jost;
            font-size: 0.688rem;
            font-weight: 400;
            color: $color-grey;
          }
        }
      }
    }

    .label {
      padding-top: 0.75rem;
      padding-left: 0.75rem;
      padding-right: 0.75rem;

      @include bp(s) {
        padding: 1.5rem;
      }

      h5 {
        margin: 0 0 0.5rem;
        font-weight: 700;
        line-height: 1.5;
        font-size: 0.875rem;
        font-family: $jost;
      }

      p {
        margin: 0;
        line-height: 1.57143;
        font-size: 0.75rem;
        font-family: $jost;
        font-weight: 400;
        color: rgb(99, 115, 129);

        &.selectedFileLabel {
          margin-top: 1.25rem;
          margin-bottom: 0.375rem;
          color: $color-grey;
          font-size: 0.688rem;

          .selectedFileIcon {
            color: $color-grey;
            margin-right: 0.375rem;
          }
        }
      }

      .fileError {
        font-family: $jost;
        font-weight: 400;
        font-size: 0.75rem;
        color: $red-color;
      }
    }
  }
}

.multipleImageContainer {
  border: 0.0625rem dashed $color-grey-light;
  margin-top: 1.25rem;
  border-radius: 0.625rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
  padding: 0.625rem;

  .imageContainer {
    width: 8.75rem;
    height: 6.875rem;
    position: relative;
    overflow: hidden;

    .delete {
      position: absolute;
      right: 0;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 5rem;
      height: 1.5rem;
      width: 1.5rem;

      &:hover {
        cursor: pointer;
      }
    }

    .firstImageLabelTitle {
      position: absolute;
      left: 0;
      bottom: 0;
      border-radius: 0.3125rem;
      padding: 0.3125rem;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 0.75rem;
      background-color: $image-featured-label-background;
      color: $color-white;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.5rem;
    }
  }

  .sortableItem {
    touch-action: none;
    cursor: grab;

    &.isDragging {
      cursor: grabbing;
    }
  }
}
