@import '../../../styles/variables';

.stickyContactBox {
  position: fixed;
  bottom: 0;
  right: 0;
  min-width: 12.5rem;
  background-color: $primary-main;
  z-index: 16;

  .contactBoxContent {
    min-height: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    .iconBox {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-white;
      gap: 0.125rem;
      font-size: 1.125rem;
      width: 2rem;
      height: 1.25rem;
      font-family: $roboto;

      a {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
