@import '../../styles/variables';

.button {
  height: 48px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);

  &:global(.MuiButton-containedPrimary) {
    background-color: $primary-button-background;
    box-shadow: $primary-button-shadow;
    border-radius: 6px;
    padding: 11px 40px;
    font-family: $jost;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    height: 50px;

    &:global(.Mui-disabled) {
      box-shadow: none;
      background-color: rgba(145, 158, 171, 0.24);
    }

    &:hover {
      background-color: $primary-button-background-hover;
    }
  }

  &:global(.MuiButton-containedSecondary) {
    background-color: $color-white;
    box-shadow: $primary-button-shadow;
    border-radius: 6px;
    padding: 11px 40px;
    font-family: $jost;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    height: 50px;
    color: $primary-main;

    &:global(.Mui-disabled) {
      box-shadow: none;
      background-color: rgba(145, 158, 171, 0.24);
    }

    &:hover {
      background-color: $color-grey-light;
    }
  }

  &:global(.MuiButton-textPrimary) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-weight: 700;
    line-height: 1.71429;
    font-size: 0.875rem;
    text-transform: capitalize;
    font-family: $jost;
    min-width: 64px;
    padding: 6px 12px;
    border-radius: 6px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: $primary-button-background;

    &:hover {
      background-color: $text-button-hover;
    }
  }

  &.saveButton {
    height: auto;
    padding: 6px 48px;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.textButton {
    opacity: 0.8;
    height: auto;
  }

  &.isActive {
    opacity: 1;
  }

  &.onlyIcon {
    padding-left: 16px;
    padding-right: 16px;
    min-width: 40px;

    :global(.MuiButton-startIcon) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .loadingIcon {
    margin-right: 4px;
  }

  &.publicMainButton {
    font-family: $euclidFlex;
    outline: none;
    border: none;
    text-transform: uppercase;
    color: $color-white;
    display: flex;
    padding: 1.125rem 1.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.375rem;
    background-color: $sand-color;
    text-align: center;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;

    &:hover {
      background-color: $sand-darker-color;
    }
  }
}
