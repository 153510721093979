@import '../../styles/variables';
@import '../../styles/mixins';

.navigationContainer {
  display: flex;
  align-items: center;
  z-index: 100;
  width: 100%;
  height: 3.625rem;
  position: fixed;
  background-color: $primary-main-lighter;
  top: 0;
  transition: top 0.3s ease;

  &.isHidden {
    top: -6.25rem;
  }

  .languageSwitcher {
    order: 0;
    padding-right: 0;
    padding-left: 0.5rem;

    @include bp(m) {
      padding-right: 1rem;
      padding-left: 0;
      order: 1;
    }
  }

  @include bp(m) {
    height: 6.25rem;
  }

  .innerNavigation {
    order: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.125rem;
    width: 100%;
    box-sizing: border-box;
    padding-left: 0;

    @include bp(m) {
      padding-left: 4.375rem;
    }

    @include bp(m) {
      justify-content: center;
    }

    .logo {
      margin: 0.625rem 0 0 2.5rem;
      width: 100%;
      text-align: center;

      @include bp(m) {
        margin: 0.938rem 3.125rem 0 3.125rem;
        width: auto;
      }

      img {
        height: 2.438rem;

        @include bp(m) {
          height: 4.625rem;
        }
      }
    }

    .iconItems {
      display: flex;
      align-items: center;
      justify-content: right;

      .drawerIcon {
        color: $bright-color;
        z-index: 15;
      }
    }

    .navigationItems {
      display: flex;
      flex-direction: row;
      gap: 20px;

      @include bp(l) {
        gap: 34px;
      }

      a {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        color: $color-white;
        font-family: $euclidFlex;
        text-transform: uppercase;

        &:hover {
          text-shadow: 0.125rem 0.125rem 0.25rem $black-color;
        }
      }
    }

    .mobileMenu {
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100vh;
      min-height: min-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 2.5rem;
      padding-bottom: 10px;
      z-index: 8;
      box-shadow: $public-navigation-box-shadow;
      background-color: $primary-main;
      overflow-y: auto;

      .navigationItem {
        display: flex;
        width: 100%;
        justify-content: left;
        padding: 0.875rem;
        align-items: center;
        position: relative;
        box-sizing: border-box;
        outline: 0;
        border: 0;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-decoration: none;
        text-transform: uppercase;
        font-family: $euclidFlex;
        min-width: 64px;
        border-radius: 0;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        margin: 0 0 4px;
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.375rem;
        color: $color-white;

        &:hover {
          background-color: $primary-dark;
        }

        &.activeSubItem {
          background-color: $primary-dark;
          color: $bright-color;
          font-weight: 600;
        }

        .navigationIcon {
          margin-right: 10px;
        }
      }
    }
  }
}
