$euclidFlex: 'Euclid Flex', sans-serif;
$roboto: 'Roboto', sans-serif;
$jost: 'Jost', sans-serif;
$dmSans: 'DM Sans', sans-serif;
$josefinSans: 'Josefin Sans', sans-serif;
$montserrat: 'Montserrat', sans-serif;

$error-text: rgb(122, 12, 46);
$error-background: rgb(255, 231, 217);
$error-icon: rgb(255, 72, 66);

$primary-light: #242c3a;
$primary-main: #1e2530;
$primary-main-lighter: rgba(30, 37, 48, 0.85);
$primary-dark: #161c25;
$primary-extra-dark: #11161e;
$primary-darker: $primary-extra-dark;
$primary-darkest: $primary-extra-dark;

$color-white: #ffffff;
$color-white-darker: rgba(255, 255, 255, 0.7);
$color-off-white: #f3f1f2;
$color-off-white-second: #d9d8d3;

$primary-button-background: #1e2530;
$primary-button-background-hover: #161c25;
$text-button-hover: rgba(30, 37, 48, 0.03);
$primary-button-shadow: rgba(28, 49, 68, 0.12) 0 4px 8px 0;

$color-grey: rgb(126, 136, 143);
$color-grey-light: rgb(218, 222, 227);
$color-grey-darker: #767980;
$color-grey-text: #d9d9d9;
$color-grey-icon: #131a29;
$color-grey-border: #ededed;
$color-orange: #e9471d;
$color-orange-darker: #ee4216;

$label: rgb(145, 158, 171);
$label-focused: $primary-light;
$label-error: #d32f2f;

$input-border: rgba(145, 158, 171, 0.32);
$input-border-focused: $primary-light;
$input-border-error: $label-error;
$input-error-text: $label-error;

$background-color: #e8e9eb;
$dark-background: #333333;

$separator-color: rgba(227, 87, 36, 0.2);
$input-border-color: rgba(227, 87, 36, 0.5);
$placeholder-color: rgba(227, 87, 36, 0.6);

$bright-color: #ffffff;
$black-color: #000000;

$red-color: #e13119;
$red-color-darker: #e04321;
$red-color-brighter: #efc5bd;

$warning-color: #f19a3e;
$warning-color-brighter: #f6c48d;
$warning-color-darker: #98540b;

$sidebar-color: #313638;
$navigation-color: #313638;

$success-color: rgb(126, 224, 129);

$error-color: rgb(237, 37, 78);

$info-color: rgb(12, 83, 183);

$checkbox-hover: rgba(30, 37, 48, 0.03);
$checkbox-hover: rgba(25, 118, 210, 0.06);

$notification-popover-shadow: rgba(145, 158, 171, 0.24);
$notification-popover-not-seen-background: rgba(30, 37, 48, 0.06);
$notification-popover-hover-background: rgb(244, 246, 248);

$sand-color: #ada191;
$sand-darker-color: #9a805d;

$light-brown-background: rgba(173, 161, 145, 0.75);
$lightest-brown-background: rgba(173, 161, 145, 0.7);

$carousel-arrow-background: rgba(30, 36, 48, 0.5);
$carousel-arrow-hover-background: rgba(30, 36, 48, 0.7);

$heading-color: #181c23;

$public-navigation-box-shadow: rgba(34, 51, 84, 0.2) 0 2px 8px -3px,
  rgba(34, 51, 84, 0.1) 0px 5px 22px -4px;

$gold-color: #9a805d;

$text-low-opacity-background: linear-gradient(
  180deg,
  rgba(118, 123, 134, 0.3) 0%,
  rgba(118, 123, 134, 0) 100%
);

$color-slate-grey: #707d83;
$color-light-grey: #f9f9f9;
$color-light-grey-darker: #e6e5e5;

$agent-card-backgound: rgba(222, 222, 222, 0.25);

$grey-box-shadow: rgba(145, 158, 171, 0.24) 0 0 0.125rem 0,
  rgba(145, 158, 171, 0.24) 0 1rem 2rem -0.25rem;

$lighter-grey-box-shadow: rgba(159, 162, 191, 0.18) 0 0.5625rem 1rem,
  rgba(159, 162, 191, 0.32) 0 0.125rem 0.125rem;

$dark-blue: rgb(33, 43, 54);

$white-transparent-background: rgba(255, 255, 255, 0.7);
$feature-popover-background: rgba(30, 36, 48, 0.5);
$light-grey: #f0f0ef;

$dark-grey-semi-transparent-background: rgba(30, 37, 48, 0.5);
$sea-blue: #023242;

$overlay-gradient: linear-gradient(
  180deg,
  rgba(24, 28, 35, 0.5) 0%,
  rgba(24, 28, 35, 0) 100%
);

$green: rgb(34, 154, 22);

$image-featured-label-background: rgba(52, 152, 219, 0.85);
$contact-form-shadow: 0px 0px 3.75rem 1.875rem rgba(0, 0, 0, 0.03);

$location-platform-card-separator: rgb(224, 224, 224);
$reject-button-color: rgb(183, 33, 54);
$reject-button-background: rgba(255, 72, 66, 0.16);
$assign-button-color: rgb(34, 154, 22);
$assign-button-background: rgba(84, 214, 44, 0.08);
$language-form-info-color: rgb(4, 41, 122);
